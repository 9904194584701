<template>
	<div class="tabsControls">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: "TabsControls",
};
</script>

<style lang="scss">
.tabsControls{
	display: flex;
	box-shadow: 0 -1px 0 0 inset #000;
	.item{
		padding: 15px;
		@include t4;
		color: $cgray3;
		cursor: pointer;
		&.active{
			color: $cblacktxt;
			background-color: #fff;
			border-top: 1px solid #000;
			border-left: 1px solid #000;
			border-right: 1px solid #000;
		}
	}
}
</style>