var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('transition',{attrs:{"name":"fade"}},[(_vm.loaded)?_c('div',[_c('section',{staticClass:"productSingle"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"firstCol"},[_c('div',{staticClass:"breadWrp"},[_c('bread-crumbs',{attrs:{"items":[
                                        {
                                            text: _vm.$t('pages.main'),
                                            url: {
                                                name: 'MainPage',
                                                params: {
                                                    locale:
                                                        _vm.$i18n.locale == 'uk'
                                                            ? null
                                                            : _vm.$i18n.locale
                                                }
                                            }
                                        },
                                        {
                                            text: _vm.$t('pages.products'),
                                            url: {
                                                name: 'products',
                                                params: {
                                                    locale:
                                                        _vm.$i18n.locale == 'uk'
                                                            ? null
                                                            : _vm.$i18n.locale
                                                }
                                            }
                                        } ].concat( _vm.categoryBc
                                    ),"last":true}}),_c('a',{staticClass:"back",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t("back"))+" "),_c('span',{staticClass:"decoLine1"})])],1),(_vm.similar.length)?_c('div',{staticClass:"relatedWrp"},[_c('div',{staticClass:"relatedProdSide"},[_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t("productpage.similar"))+" ")]),_vm._l((_vm.similar),function(item,index){return _c('product-grid-card',{key:index,attrs:{"d":item,"sidemode":"true"}})})],2)]):_vm._e(),_c('div',{staticClass:"sideSectionWrp"},[_c('side-sections')],1)]),(_vm.translate && _vm.model)?_c('div',{staticClass:"secondCol"},[_c('h1',{staticClass:"ProdName"},[_vm._v(_vm._s(_vm.translate.name))]),_c('tabs-controls',[_c('div',{staticClass:"item",class:{ active: _vm.currentTab == 0 },on:{"click":function($event){_vm.currentTab = 0}}},[_vm._v(" "+_vm._s(_vm.$t("product.prodInfo"))+" ")]),_c('div',{staticClass:"item",class:{ active: _vm.currentTab == 2 },on:{"click":function($event){_vm.currentTab = 2}}},[_vm._v(" "+_vm._s(_vm.$t("product.prodDocs"))+" ")])]),_c('div',{staticClass:"tabBody"},[(_vm.currentTab == 0)?_c('div',{staticClass:"tab"},[_c('div',{staticClass:"detailsGrid"},[_c('div',[(_vm.model.image)?_c('div',{staticClass:"imgWrp"},[_c('img',{staticClass:"img",attrs:{"src":_vm.path(_vm.model.image),"alt":_vm.path(_vm.model.image)}}),_c('img',{staticClass:"sticker",attrs:{"src":_vm.descImg,"alt":""}})]):_vm._e()]),_c('div',{staticClass:"prodInfo"},[_c('div',{staticClass:"group"},[_c('div',{staticClass:"heading"},[_vm._v(" "+_vm._s(_vm.$t( "productpage.charInfo" ))+" ")]),_c('div',{staticClass:"slide"},[_c('div',{staticClass:"grid"},[_c('div'),_c('a',{ref:"instrutcionlink",staticClass:"docLink",attrs:{"href":_vm.pathFile(
                                                                    _vm.translate.instruction
                                                                ),"target":"_blank"}},[_c('span',[_vm._v(_vm._s(_vm.$t( "productpage.pdfInstruction" )))]),_c('span',{staticClass:"ic-donwload"})])]),_c('div',{staticClass:"grid"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t( "productpage.sklad" )))]),_c('p',{staticClass:"txt"},[_vm._v(" "+_vm._s(_vm.translate.composition)+" ")])]),_c('div',{staticClass:"grid"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t( "productpage.licarForma" )))]),_c('p',{staticClass:"txt"},[_vm._v(" "+_vm._s(_vm.translate.release_form)+" ")])]),_c('div',{staticClass:"grid"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t( "productpage.terminpridatnosti" )))]),_c('p',{staticClass:"txt"},[_vm._v(" "+_vm._s(_vm.translate.age)+" ")])]),_c('div',{staticClass:"grid"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t( "productpage.umovisberigania" )))]),_c('p',{staticClass:"txt"},[_vm._v(" "+_vm._s(_vm.translate.storage_conditions)+" ")])]),(
                                                            _vm.translate.package
                                                                .length
                                                        )?_c('div',{staticClass:"grid"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t( "productpage.package" )))]),_c('ul',{staticClass:"txt"},_vm._l((_vm.translate.package),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),0)]):_vm._e()])]),_c('div',{staticClass:"group"},[_c('div',{staticClass:"heading"},[_vm._v(" "+_vm._s(_vm.$t( "productpage.indications1" ))+" "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toInstr.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t( "productpage.indications2" )))]),_vm._v(") ")]),_c('div',{staticClass:"slide"},_vm._l((_vm.translate.indication),function(item,index){return _c('div',{key:index,staticClass:"testimonyItem"},[_vm._v(" "+_vm._s(item)+" ")])}),0)]),_c('div',{staticClass:"group"},[_c('div',{staticClass:"heading"},[_vm._v(" "+_vm._s(_vm.$t( "productpage.desindications1" ))+" "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toInstr.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t( "productpage.desindications2" )))]),_vm._v(") ")]),_c('div',{staticClass:"slide"},_vm._l((_vm.translate.contraindications),function(item,index){return _c('div',{key:index,staticClass:"testimonyItem"},[_vm._v(" "+_vm._s(item)+" ")])}),0)])])])]):(_vm.currentTab == 1)?_c('div',{staticClass:"tab"},[_c('div',{staticClass:"instructionHeading"},[_vm._v(" "+_vm._s(_vm.$t("productpage.instruction"))+" ")]),_c('div',{staticClass:"detailsGrid"},[_c('div',{staticClass:"sideArticlesWrp"},[_c('side-article-captions',{attrs:{"items":_vm.translate.anchor_menu}})],1),_c('div',{staticClass:"ArticleText ArticleTxt"},[_c('p',[_c('a',{staticClass:"pdfLink",attrs:{"href":_vm.pathFile(
                                                            _vm.translate.instruction
                                                        )}},[_vm._v(_vm._s(_vm.$t( "productpage.pdfInstruction" )))])]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("productpage.pdftip"))+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.translate.description)}})])])]):_vm._e(),(_vm.currentTab == 2)?_c('div',{staticClass:"tab"},[_c('div',{staticClass:"detailsGrid doc"},[_c('div',{staticClass:"prodInfo"},[_c('div',{staticClass:"group"},[_c('div',{staticClass:"heading"},[_vm._v(" "+_vm._s(_vm.$t( "productpage.publication" ))+" ")]),_c('div',{staticClass:"slide"},_vm._l((_vm.translate.publication),function(item,index){return _c('div',{key:index,staticClass:"grid"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(item.name))]),_c('a',{staticClass:"publication",attrs:{"href":_vm.pathFile(
                                                                    item.file
                                                                ),"target":"_blank"}},[_c('span',[_vm._v(_vm._s(item.title))]),_c('i',{staticClass:"ic-donwload"})])])}),0)])])])]):_vm._e()]),_c('router-link',{staticClass:"button2 left",attrs:{"to":{
                                    name: 'products',
                                    params: {
                                        locale: _vm.locale == 'uk' ? null : _vm.locale
                                    }
                                }}},[_vm._v(_vm._s(_vm.$t("pages.allproducts"))+" "),_c('span',{staticClass:"ic-icarrow-left"})])],1):_vm._e()])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }