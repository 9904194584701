<template>
	<div class="sideSection">
		<div class="caption">{{ $t("seealsochaprers") }}</div>
		<template v-if="$store.state.productMenu">
			<a
				v-for="(item, index) in $store.state.productMenu"
				:key="index"
				:href="item.url"
				target="_blank"
				>{{ item.name }}</a
			>
		</template>
	</div>
</template>

<script>
export default {
	name: "SideSections",
};
</script>

<style lang="scss">
.sideSection {
	padding-top: 20px;
	.caption {
		@include t4;
		margin-bottom: 15px;
	}
	a {
		display: block;
		padding: 10px;
		@include t4;
		background-color: $cgray6;
		text-decoration: none;
		& + a {
			margin-top: 2px;
		}
	}
}
</style>