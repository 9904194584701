<template>
    <main>
        <transition name="fade">
            <div v-if="loaded">
                <section class="productSingle">
                    <div class="container">
                        <div class="firstCol">
                            <div class="breadWrp">
                                <bread-crumbs
                                    :items="[
                                        {
                                            text: $t('pages.main'),
                                            url: {
                                                name: 'MainPage',
                                                params: {
                                                    locale:
                                                        $i18n.locale == 'uk'
                                                            ? null
                                                            : $i18n.locale
                                                }
                                            }
                                        },
                                        {
                                            text: $t('pages.products'),
                                            url: {
                                                name: 'products',
                                                params: {
                                                    locale:
                                                        $i18n.locale == 'uk'
                                                            ? null
                                                            : $i18n.locale
                                                }
                                            }
                                        },
                                        ...categoryBc
                                    ]"
                                    :last="true"
                                ></bread-crumbs>
                                <a href="#" class="back"
                                    >{{ $t("back") }}
                                    <span class="decoLine1"></span
                                ></a>
                            </div>
                            <div class="relatedWrp" v-if="similar.length">
                                <div class="relatedProdSide">
                                    <div class="caption">
                                        {{ $t("productpage.similar") }}
                                    </div>
                                    <product-grid-card
                                        v-for="(item, index) in similar"
                                        :key="index"
                                        :d="item"
                                        sidemode="true"
                                    ></product-grid-card>
                                </div>
                            </div>
                            <div class="sideSectionWrp">
                                <side-sections></side-sections>
                            </div>
                        </div>
                        <div class="secondCol" v-if="translate && model">
                            <h1 class="ProdName">{{ translate.name }}</h1>
                            <tabs-controls>
                                <div
                                    class="item"
                                    :class="{ active: currentTab == 0 }"
                                    @click="currentTab = 0"
                                >
                                    {{ $t("product.prodInfo") }}
                                </div>
                                <div
                                    class="item"
                                    :class="{ active: currentTab == 2 }"
                                    @click="currentTab = 2"
                                >
                                    {{ $t("product.prodDocs") }}
                                </div>
                                <!-- <div
									class="item"
									:class="{ active: currentTab == 1 }"
									@click="currentTab = 1"
								>
									{{ $t("product.prodInstruction") }}
								</div> -->
                            </tabs-controls>
                            <div class="tabBody">
                                <div class="tab" v-if="currentTab == 0">
                                    <div class="detailsGrid">
                                        <div>
                                            <div
                                                class="imgWrp"
                                                v-if="model.image"
                                            >
                                                <img
                                                    class="img"
                                                    :src="path(model.image)"
                                                    :alt="path(model.image)"
                                                />
                                                <img
                                                    class="sticker"
                                                    :src="
                                                       descImg
                                                    "
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                        <div class="prodInfo">
                                            <div class="group">
                                                <div class="heading">
                                                    {{
                                                        $t(
                                                            "productpage.charInfo"
                                                        )
                                                    }}
                                                    <!-- <span class="ic-icclose"></span> -->
                                                </div>
                                                <div class="slide">
                                                    <div class="grid">
                                                        <div></div>
                                                        <a
                                                            :href="
                                                                pathFile(
                                                                    translate.instruction
                                                                )
                                                            "
                                                            target="_blank"
                                                            class="docLink"
                                                            ref="instrutcionlink"
                                                        >
                                                            <span>{{
                                                                $t(
                                                                    "productpage.pdfInstruction"
                                                                )
                                                            }}</span>
                                                            <span
                                                                class="ic-donwload"
                                                            ></span>
                                                        </a>
                                                    </div>
                                                    <div class="grid">
                                                        <span class="title">{{
                                                            $t(
                                                                "productpage.sklad"
                                                            )
                                                        }}</span>
                                                        <p class="txt">
                                                            {{
                                                                translate.composition
                                                            }}
                                                        </p>
                                                    </div>
                                                    <div class="grid">
                                                        <span class="title">{{
                                                            $t(
                                                                "productpage.licarForma"
                                                            )
                                                        }}</span>
                                                        <p class="txt">
                                                            {{
                                                                translate.release_form
                                                            }}
                                                        </p>
                                                    </div>
                                                    <div class="grid">
                                                        <span class="title">{{
                                                            $t(
                                                                "productpage.terminpridatnosti"
                                                            )
                                                        }}</span>
                                                        <p class="txt">
                                                            {{ translate.age }}
                                                        </p>
                                                    </div>
                                                    <div class="grid">
                                                        <span class="title">{{
                                                            $t(
                                                                "productpage.umovisberigania"
                                                            )
                                                        }}</span>
                                                        <p class="txt">
                                                            {{
                                                                translate.storage_conditions
                                                            }}
                                                        </p>
                                                    </div>
                                                    <div
                                                        v-if="
                                                            translate.package
                                                                .length
                                                        "
                                                        class="grid"
                                                    >
                                                        <span class="title">{{
                                                            $t(
                                                                "productpage.package"
                                                            )
                                                        }}</span>
                                                        <ul class="txt">
                                                            <li
                                                                v-for="(item,
                                                                index) in translate.package"
                                                                :key="index"
                                                            >
                                                                {{ item }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="group">
                                                <div class="heading">
                                                    {{
                                                        $t(
                                                            "productpage.indications1"
                                                        )
                                                    }}
                                                    <a
                                                        href="#"
                                                        @click.prevent="toInstr"
                                                        >{{
                                                            $t(
                                                                "productpage.indications2"
                                                            )
                                                        }}</a
                                                    >)
                                                    <!-- <span class="ic-icclose"></span> -->
                                                </div>
                                                <div class="slide">
                                                    <div
                                                        v-for="(item,
                                                        index) in translate.indication"
                                                        :key="index"
                                                        class="testimonyItem"
                                                    >
                                                        {{ item }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="group">
                                                <div class="heading">
                                                    {{
                                                        $t(
                                                            "productpage.desindications1"
                                                        )
                                                    }}
                                                    <a
                                                        href="#"
                                                        @click.prevent="toInstr"
                                                        >{{
                                                            $t(
                                                                "productpage.desindications2"
                                                            )
                                                        }}</a
                                                    >)
                                                </div>
                                                <div class="slide">
                                                    <div
                                                        v-for="(item,
                                                        index) in translate.contraindications"
                                                        :key="index"
                                                        class="testimonyItem"
                                                    >
                                                        {{ item }}
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="group">
                                                <div class="heading">
                                                    {{
                                                        $t(
                                                            "productpage.publication"
                                                        )
                                                    }}
                                                </div>
                                                <div class="slide">
                                                    <div
                                                        v-for="(item,
                                                        index) in translate.publication"
                                                        :key="index"
                                                        class="grid"
                                                    >
                                                        <span class="title">{{
                                                            item.name
                                                        }}</span>
                                                        <a
                                                            :href="
                                                                pathFile(
                                                                    item.file
                                                                )
                                                            "
                                                            target="_blank"
                                                            class="publication"
                                                        >
                                                            <span>{{
                                                                item.title
                                                            }}</span>
                                                            <i
                                                                class="ic-donwload"
                                                            ></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="tab" v-else-if="currentTab == 1">
                                    <div class="instructionHeading">
                                        {{ $t("productpage.instruction") }}
                                        <!-- <span class="ic-icclose"></span> -->
                                    </div>
                                    <div class="detailsGrid">
                                        <div class="sideArticlesWrp">
                                            <side-article-captions
                                                :items="translate.anchor_menu"
                                            ></side-article-captions>
                                        </div>
                                        <div class="ArticleText ArticleTxt">
                                            <p>
                                                <a
                                                    :href="
                                                        pathFile(
                                                            translate.instruction
                                                        )
                                                    "
                                                    class="pdfLink"
                                                    >{{
                                                        $t(
                                                            "productpage.pdfInstruction"
                                                        )
                                                    }}</a
                                                >
                                            </p>
                                            <p>
                                                {{ $t("productpage.pdftip") }}
                                            </p>
                                            <div v-html="translate.description">
                                                <!-- <a href="#" class="pdfLink">Інструкцію PDF</a>

												<h5>Склад</h5>
												<p>Діюча речовина: albumin;</p>
												<p>1 мл препарату містить альбуміну людини 100 мг;</p>
												<p>Допоміжні речовини: натрію каприлат, вода для ін’єкцій.</p>

												<h5>Лікарська форма</h5>
												<p>Розчин для інфузій.</p>
												<p>
													Основні фізико-хімічні властивості: прозора в’язка рідина
													від жовтуватого до світло-коричневого кольору, допускається
													зеленуватий відтінок.
												</p> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab" v-if="currentTab == 2">
                                    <div class="detailsGrid doc">
                                        <div class="prodInfo">
                                            <div class="group">
                                                <div class="heading">
                                                    {{
                                                        $t(
                                                            "productpage.publication"
                                                        )
                                                    }}
                                                    <!-- <span class="ic-icclose"></span> -->
                                                </div>
                                                <div class="slide">
                                                    <div
                                                        v-for="(item,
                                                        index) in translate.publication"
                                                        :key="index"
                                                        class="grid"
                                                    >
                                                        <span class="title">{{
                                                            item.name
                                                        }}</span>
                                                        <a
                                                            :href="
                                                                pathFile(
                                                                    item.file
                                                                )
                                                            "
                                                            target="_blank"
                                                            class="publication"
                                                        >
                                                            <span>{{
                                                                item.title
                                                            }}</span>
                                                            <i
                                                                class="ic-donwload"
                                                            ></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <router-link
                                :to="{
                                    name: 'products',
                                    params: {
                                        locale: locale == 'uk' ? null : locale
                                    }
                                }"
                                class="button2 left"
                                >{{ $t("pages.allproducts") }}
                                <span class="ic-icarrow-left"></span
                            ></router-link>
                        </div>
                    </div>
                </section>
                <!-- <caution-section></caution-section> -->
            </div>
        </transition>
        <!-- <modal-base v-if="$store.state.doctorAlert">
			<caution-modal
				@close="onModalClose"
				@accept="onModalClose"
			></caution-modal>
		</modal-base> -->
    </main>
</template>

<script>
import CautionModal from "../components/Modals/CautionModal.vue";
import BreadCrumbs from "../components/Parts/BreadCrumbs.vue";
import ModalBase from "../components/Parts/ModalBase.vue";
import ProductGridCard from "../components/Parts/ProductGridCard.vue";
import SideArticleCaptions from "../components/Parts/SideArticleCaptions.vue";
import SideSections from "../components/Parts/SideSections.vue";
import TabsControls from "../components/Parts/TabsControls.vue";
// import CautionSection from "../components/Sections/CautionSection.vue";
export default {
    components: {
        ProductGridCard,
        SideSections,
        BreadCrumbs,
        TabsControls,
        SideArticleCaptions,
        // CautionSection,
        ModalBase,
        CautionModal
    },
    name: "ProductShowcase",
    data() {
        return {
            currentTab: 0,
            loaded: false,

            model: null,
            translate: null,
            similar: null
        };
    },
    computed: {
        descImg() {
			switch (this.locale) {
				case "uk":
					return require(`@/assets/img/label-ua.svg`);
				case "ru":
					return require(`@/assets/img/label-rus.svg`);
				case "en":
					return require(`@/assets/img/label-en.svg`);
			}
		},
        categoryBc() {
            return {
                text: this.model.categoryName,
                url: {
                    name: "productscategory",
                    params: {
                        locale: this.locale == "uk" ? null : this.locale,
                        category: this.model.categorySlug
                    }
                }
            };
        }
    },
    methods: {
        onModalClose() {
            this.$store.dispatch("hideDoctor");
        },
        toInstr() {
            let ele = this.$refs.instrutcionlink;
            if (ele) {
                const rect = ele.getBoundingClientRect();
                const top = rect.top + window.scrollY - 30;
                window.scrollTo({
                    top: top,
                    behavior: "smooth"
                });
            }
        }
    },
    created() {
        this.axios
            .post("/api/products/get-by-slug", {
                lang: this.$i18n.locale,
                slug: this.$route.params.slug
            })
            .then(response => {
                this.model = response.data.data.model;
                this.translate = response.data.data.translate;
                this.similar = response.data.data.similar.models;
                document.title =
                    response.data.data.translate.meta_title ||
                    response.data.data.translate.name;
                this.loaded = true;
            })
            .catch(({ response }) => {
                this.solveStatus(response.status);
            });
    }
};
</script>

<style lang="scss">
.productSingle {
    padding: 30px 0 60px;
    .container {
        @include container;
        display: grid;
        grid-template-columns: 1fr 3fr;
        @media (max-width: $tl) {
            grid-template-columns: 1fr;
        }
        .firstCol {
            margin-right: 50px;
            border-right: 1px solid $cgray5;
            @media (max-width: $tl) {
                margin-right: 0;
            }
            .breadWrp {
                @media (max-width: $tl) {
                    order: 1;
                    border-bottom: unset !important;
                }
                @media (max-width: $ts) {
                    padding: 10px 0;
                }
                .breadCrumbs {
                    margin-bottom: 45px;
                    @media (max-width: $tl) {
                        display: none;
                    }
                }
                .back {
                    display: none;
                    color: $cred1;
                    position: relative;
                    text-decoration: none;
                    padding-bottom: 3px;
                    .decoLine1 {
                        background-color: $cred1;
                    }
                    @media (max-width: $ts) {
                        display: inline-block;
                    }
                }
            }
            .relatedWrp {
                border-top: 1px solid $cgray5;
                padding: 20px 0;
                @media (max-width: $tl) {
                    display: none;
                }
                .caption {
                    @include t4;
                    margin-bottom: 15px;
                }
            }
            .sideSectionWrp {
                border-top: 1px solid $cgray5;
                padding-right: 15px;
                @media (max-width: $tl) {
                    display: none;
                }
            }
        }
        .secondCol {
            .ProdName {
                @include h1tabl;
                margin-bottom: 30px;
                @media (max-width: $ts) {
                    @include h1mob;
                }
            }

            .tabBody {
                .tab {
                    .detailsGrid {
                        display: grid;
                        grid-template-columns: 1fr 2fr;
                        @media (max-width: $tm) {
                            grid-template-columns: 1fr;
                        }
                        &.doc {
                            grid-template-columns: 1fr;
                        }
                    }
                    .imgWrp {
                        position: relative;
                        padding-top: 15px;
                        @media (max-width: $tm) {
                            margin-bottom: 30px;
                        }
                        .img {
                            width: 100%;
                            @media (max-width: $tm) {
                                max-width: 345px;
                            }
                        }
                        .sticker {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 130px;
                            height: 130px;
                        }
                    }
                    .prodInfo {
                        padding-left: 70px;
                        @media (max-width: $tm) {
                            padding-left: 0;
                        }
                        .group {
                            padding-top: 5px;
                            & + .group {
                                border-top: 1px solid $cgray3;
                            }
                            .heading {
                                @include t3mob;
                                padding: 10px 0;
                                padding-right: 36px;
                                position: relative;
                                a {
                                    color: $cred1;
                                }
                                .ic-icclose {
                                    position: absolute;
                                    top: 50%;
                                    right: 10px;
                                    transform: translate(0, -50%);
                                    font-size: 20px;
                                    cursor: pointer;
                                }
                            }
                            .slide {
                                .grid {
                                    display: grid;
                                    grid-template-columns: 1fr 3fr;
                                    grid-gap: 30px;
                                    padding: 10px 0;
                                    & + .grid {
                                        border-top: 1px solid $cgray4;
                                    }
                                }
                                .docLink {
                                    font-style: normal;
                                    font-weight: 300;
                                    font-size: 21px;
                                    line-height: 100%;
                                    letter-spacing: -0.025em;
                                    text-decoration-line: underline;
                                    color: #e1251b;

                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    .ic-donwload {
                                        margin-left: 15px;
                                        width: 36px;
                                        height: 36px;
                                        border-radius: 50%;
                                        background-color: #e1251b;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        font-size: 20px;
                                        color: #fff;
                                    }
                                }
                                .title {
                                    @include t4;
                                    color: $cgray2;
                                }
                                .txt {
                                    margin: 0;
                                    @include t3l;
                                }
                                ul {
                                    @include ulMarker;
                                }
                                .testimonyItem {
                                    padding: 10px 0 25px 30px;
                                    @include t3l;
                                    position: relative;
                                    &:before {
                                        content: "\e909";
                                        position: absolute;
                                        top: 10px;
                                        left: 0;
                                        font-size: 19px;
                                        color: $cgray3;

                                        font-family: "Biopharma" !important;
                                        speak: never;
                                        font-style: normal;
                                        font-weight: normal;
                                        font-variant: normal;
                                        text-transform: none;
                                        line-height: 1;
                                        -webkit-font-smoothing: antialiased;
                                        -moz-osx-font-smoothing: grayscale;
                                    }
                                    & + .testimonyItem {
                                        border-top: 1px solid $cgray4;
                                    }
                                }
                                .publication {
                                    display: flex;
                                    justify-content: space-between;
                                    text-decoration: none;
                                    span {
                                        text-decoration: underline;
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 14px;
                                        line-height: 130%;
                                        letter-spacing: 0.02em;
                                        text-decoration-line: underline;
                                        color: #111325;
                                    }
                                    .ic-donwload {
                                        margin-left: 15px;
                                        width: 44px;
                                        height: 44px;
                                        border-radius: 50%;
                                        background-color: #e1251b;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        font-size: 20px;
                                        color: #fff;
                                        flex-shrink: 0;
                                    }
                                    &:hover {
                                        span {
                                            color: $cred1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .instructionHeading {
                        @include t3mob;
                        padding: 15px 0 10px;
                        padding-right: 36px;
                        position: relative;
                        .ic-icclose {
                            position: absolute;
                            top: 50%;
                            right: 10px;
                            transform: translate(0, -50%);
                            font-size: 20px;
                            cursor: pointer;
                        }
                    }
                    .sideArticlesWrp {
                        padding-top: 30px;
                    }
                    .ArticleText {
                        padding-left: 70px;
                        @media (max-width: $tm) {
                            padding-left: 0;
                            margin-top: 20px;
                        }
                        & > *:first-child {
                            margin-top: 0;
                        }
                        p {
                            @include t3l;
                        }
                        h2 {
                            @include h2mob;
                            margin: 35px 0 25px;
                        }
                        h3 {
                            @include h3;
                            margin: 35px 0 25px;
                        }
                        h4 {
                            @include h4;
                            margin: 30px 0 20px;
                        }
                        h5 {
                            @include h5;
                            margin: 25px 0 20px;
                        }
                        h6 {
                            @include h6;
                            margin: 25px 0 15px;
                        }
                        ol {
                            list-style: none;
                            counter-reset: olcounter;
                            padding: 0;
                            padding-left: 25px;

                            li {
                                @include t3l;
                                margin: 16px 0;
                                position: relative;
                                counter-increment: olcounter;

                                &::before {
                                    content: counter(olcounter) ".";
                                    position: absolute;
                                    top: 0px;
                                    left: -22px;
                                    @include t4;
                                    color: $cred1;
                                }

                                & > p {
                                    margin: 6px 0;

                                    & + p {
                                        margin-top: 16px;
                                    }
                                }
                            }
                        }
                        ul {
                            list-style: square;

                            padding: 0;
                            padding-left: 25px;

                            li {
                                @include t3l;
                                margin: 16px 0;
                                position: relative;

                                &::marker {
                                    color: $cred1;
                                }
                            }

                            ol > li:before {
                                content: counter(olcounter) ".";
                                position: absolute;
                                top: -1px;
                                left: -30px;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 18px;
                                color: #000000;
                                background-color: transparent;
                            }
                        }
                        & > img {
                            width: 100%;
                            margin: 16px 0;
                        }
                        .quote {
                            .txt {
                                font-style: italic;
                                font-weight: 500;
                                font-size: 18px;
                                line-height: 130%;
                                letter-spacing: 0.01em;
                                color: #111325;
                            }
                            .auth {
                                @include h6;
                                margin-top: 15px;
                                padding-bottom: 15px;
                                &::before {
                                    content: "";
                                    height: 3px;
                                    width: 16px;
                                    margin-bottom: 4px;
                                    margin-right: 10px;
                                    background-color: $cred1;
                                    display: inline-block;
                                }
                            }
                        }
                        .pdfLink {
                            font-style: normal;
                            font-weight: 300;
                            font-size: 34px;
                            line-height: 88%;
                            letter-spacing: -0.025em;
                            text-decoration-line: underline;
                            color: #e1251b;
                            display: block;
                        }
                    }
                }
            }
            .button2 {
                background-color: $cgray6;
                border: 1px solid $cgray6;
                width: 100%;
                max-width: unset;
                margin-top: 20px;
                &:hover {
                    background-color: $cred1;
                }
            }
        }
    }
}
</style>
